<script>

import BaseBadge from '@/components/Badge';

const SEGMENT_NAME = {
    behavioralDaysOff: 'Dias sem pedir',
    behavioralBirthday: 'Aniversariante',
    clientAgeRange: 'Faixa etária',
    clientRegisterTimer: 'Tempo de cadastro',
    clientAverageTicket: 'Ticket médio',
    clientOrderQuantity: 'Quantidade de conversões',
    clientTotalSpent: 'Total gasto',
    geographicDistrict: 'Região',
    regionRange: 'Região',
    weatherPhotography: 'Fotografia climática',
};

export default {
    props: {
        audiences: { type: Array, default: () => [] },
    },
    methods: {
        buildSegmentationTags(rules = []) {
            let segments = [];
            rules.forEach((s) => {
                const { value, type } = s;

                if (!value) {
                    segments.push(`${SEGMENT_NAME[type]}`)
                    return;
                } 
                
                if (value.min || value.max) {
                    segments.push(`${SEGMENT_NAME[type]} (${value.min}-${value.max})`);
                } else if (type === SEGMENT_NAME[type]) {
                    segments.push(`${value.address}`);
                } else if (value.minimum) {
                    segments.push(`${SEGMENT_NAME[type]} (${value.minimum}+)`);
                } else if (value.exact) {
                    segments.push(`${SEGMENT_NAME[type]} (${value.exact})`);
                } else if (type === 'weatherPhotography') {
                    segments = segments.concat(this.buildMultipleTags(value, type));
                } else if( type === 'regionRange') {
                    segments = segments.concat(this.buildMultipleTags(value, type));
                } else {
                    segments.push(`${SEGMENT_NAME[type]} `);
                }

                if (value.isLast && value.inLastPeriod) {
                    switch (value.inLastPeriod) {
                        case 'DAY':
                            segments.push(`No(s) último(s) ${value.isLast} dia(s)`);
                            break;
                        case 'MONTH':
                            segments.push(`No(s) último(s) ${value.isLast} mes(es)`);
                            break;
                        case 'YEAR':
                            segments.push(`No(s) último(s) ${value.isLast} ano(s)`);
                            break;
                    }
                }
            });
            return segments;
        },
        buildMultipleTags(value, type) {
        if (!['weatherPhotography', 'regionRange'].includes(type)) 
            return [];

        const configs = value;
        if (!configs) 
            return [];

        let tags = [];

        if(type == 'regionRange') {
            tags.push(...configs.map((s) => {
                if(s.cities.length == 0) {
                    return s.state;
                } else if(s.cities.length == 1) {
                    const neighborhoods = s.cities[0].neighborhoods.length > 0 ? ` (${s.cities[0].neighborhoods.length})` : '';
                    return `${s.cities[0].city} - ${s.state}${neighborhoods}`;
                } else {
                    return `${s.state} (${s.cities.length})`;
                }
            }));
            return tags;
        }              

        if (configs.temperature)
            tags.push(
            `Temperatura (${configs.temperature.min} - ${configs.temperature.max})`
            );

        if (configs.feelslike)
            tags.push(
            `Sensação (${configs.feelslike.min} - ${configs.feelslike.max})`
            );

        if (configs.moisture)
            tags.push(
            `Umidade (${configs.moisture.min} - ${configs.moisture.max})`
            );

        if (configs.weather) {
            const featureTags = configs.weather.map((feature) => {
            if (feature === 'CLEAR') return 'Ensolarado';
            if (feature === 'RAIN') return 'Chovendo';
            if (feature === 'CLOUDY') return 'Nublado';
            if (feature === 'PARTLY_CLOUDY') return 'Parcialmente nublado';
            return '';
            });
            tags = tags.concat(featureTags);
        }

        if (configs.quantity) tags.push(`${configs.quantity.exact} Pedidos`);

        return tags;
        },
        renderBadges(h, audience) {
            if (!audience.last_segmentation) {
                return (
                    <div class="badges">
                        <BaseBadge name="Em análise" absolute="false" type="yellow" absolute={false} />
                    </div>
                )
            } else {
                return (
                    <div class="badges">
                        <BaseBadge name={`${audience.customers} hoje`} absolute={false}>
                            <template slot="icon">
                                <i class="fal fa-user" style="color: #fff1ada6" />
                            </template>
                        </BaseBadge>
                    </div>
                )
            }
        },
        renderTags(h, audienceRules) {
            return (
                <div class="tags">
                    {
                        this.buildSegmentationTags(audienceRules).map((ruleString, ruleIndex) => (
                            <div class="tag" key={ruleIndex}>
                                {ruleString}
                            </div>
                        ))
                    }
                </div>
            )
        },
        renderAudienceCard(h, audience, audienceIndex) {
            return (
                <div class="public" key={audienceIndex}>
                    <div class="content">
                        <div class="header">
                            <div class="d-flex">
                                <i class="fal fa-user-group" />
                                <p class="name">{audience.name}</p>
                            </div>
                            <div class="d-flex align-center">
                                {this.renderBadges(h, audience)}
                                <div class="buttons">
                                    <i class="far fa-pen" onClick={() => this.$emit('onEdit', audience.id)} />
                                    {audience.last_segmentation && <i class="far fa-arrow-down-to-bracket" onClick={() => this.$emit('onExport', audience.id)} />}
                                </div>
                            </div>
                        </div>
                        {this.renderTags(h, audience.rules)}
                    </div>
                </div>
            )
        }
    },
    render(h) {
        const { audiences } = this.$props;
        return (
            <div class="publics">
                {audiences.map((audience, audienceIndex) => this.renderAudienceCard(h, audience, audienceIndex))}
            </div>
        )
    }
}
</script>
<style scoped>

.publics {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: start;
  gap: 20px;
}

.publics .public {
    background: var(--box);
    padding: 5px;
    border-radius: 10px;
}

.publics .public .content {
    background: rgba(30, 33, 36, 0.25);
    padding: 0 15px 5px;
    border-radius: 10px;
    height: 100%;
}

.publics .public .content .header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 15px;
    border-bottom: 1px solid rgba(111, 118, 126, 0.15);
    position: relative;
    justify-content: space-between;
}

.publics .public .content .header .name {
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #cccccc;
    font-weight: bold;
    margin-left: 15px;
}

.publics .public .content .header .badges {
    border-right: 1px solid rgba(111, 118, 126, 0.25);
    margin-right: 20px;
    padding-right: 20px;
}

.publics .public .content .header .buttons {
    display: flex;
    align-items: center;
}

.publics .public .content .header .buttons>i {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.publics .public .content .header .buttons>i:hover {
    color: var(--default);
}

.publics .public .content .header i {
    color: var(--icon-inactive);
    font-size: 15px;
}

.publics .public .content .header i {
    color: var(--icon-inactive);
    font-size: 15px;
}

.tags {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.tags .tag {
    color: rgba(170, 170, 171, 0.7);
    padding: 5px 15px;
    border-radius: 10px;
    border: 1px solid rgba(111, 118, 126, 0.2);
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    background: #252a2e;
}

@media screen and (max-width: 991px) {
    .tabs {
        margin-bottom: 25px;
    }

    .tags {
        margin-top: 20px;
    }

    .publics {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .publics .public .content .header .badges {
        margin-right: 15px;
        padding-right: 15px;
    }
}
</style>