<template>
  <div>
    <base-title
      title="Fontes"
      :subtitle="unit && unit.name ? `${unit.name}` : ''"
      @addAction="openDrawer()"
      showButtonAdd
      link="fontes"
    >
      <base-button
        design="two"
        :class="isMobile ? 'reverse w-100' : 'mr_15'"
        @click="openDrawerEdit()"
      >
        <p>Editar unidade</p>
      </base-button>
      <base-button
        v-if="!isMobile"
        text="Adicionar fonte"
        @click="openDrawer()"
      />
    </base-title>

    <base-empty
      v-if="sources.length == 0"
      title="Você ainda não possui fontes"
      message="Através desta ferramenta, será possível cadastrar suas fontes"
      buttonText="Cadastrar fontes"
      @onClick="openDrawer()"
    >
      <template slot="image">
        <i class="fal fa-pen-field" />
      </template>
    </base-empty>

    <div class="sources">
      <div class="source" v-for="(source, i) in sources" :key="i">
        <div class="d-flex align-center">
          <div class="logo">
            <img src="" alt="" />
          </div>

          <div class="d-flex flex-column">
            <div class="name" @click="selectUnitSource(source)">
              {{ getSourceName(source.origin) }}
            </div>
            <base-checkbox
              :checked="source.status == 'ACTIVATED'"
              @click="() => toggleSourceStatus(source)"
            >
              <span v-if="source.status == 'ACTIVATED'"
                >Plataforma ativada</span
              >
              <span v-else>Plataforma desativada</span>
            </base-checkbox>
          </div>
        </div>

        <base-button design="four" @click="() => deleteSource(source)"
          ><i class="far fa-trash-alt"
        /></base-button>
      </div>
    </div>

    <base-drawer
      title="Editar Unidade"
      :open="drawer.edit.open"
      :close="drawer.edit.close"
    >
      <base-card>
        <base-label text="Renomeie o nome desta Unidade" class="mb_0" />
        <base-input v-model="unitName" :valid="!error" @focus="error = false" />

        <base-label text="Insira o endereço da unidade" class="mt_20 mb_0"/>
        <address-input
          :placeholder="unit.address ? getFormatedUnitAddress() : `Buscar endereço e número`"
          @selectedAddress="onAddressSelected"
        />

        <div class="d-flex justify-center mt_30">
          <base-button
            design="underline"
            text="Remover esta unidade"
            @click="deleteUnit()"
            :disabled="editLoading"
          />
        </div>
      </base-card>

      <template slot="footer">
        <base-button class="w-100" @click="save()" :disabled="editLoading"
          >Salvar</base-button
        >
      </template>
    </base-drawer>

    <unit-drawer
      :unit="unit"
      :open="drawer.open"
      :close="drawer.close"
      @new-source="getUnits(unit.id)"
      @close="drawer.close = !drawer.close"
    />

    <unit-view-drawer
      :source="showSource"
      :open="drawer.view.open"
      :close="drawer.view.close"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EnvironmentsAPI } from '@/api/Environments';

import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import BaseDrawer from '@/components/Drawer';
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';
import BaseRadio from '@/components/Radio';
import BaseCheckbox from '@/components/Checkbox';
import BaseLoading from '@/components/Loading';
import BaseInput from '@/components/Input';
import UnitDrawer from '@/containers/Unit/SourceDrawer';
import UnitViewDrawer from '@/containers/Unit/SourceViewDrawer';
import AddressInput from '@/containers/AddressInput';

const components = {
  BaseTitle,
  BaseEmpty,
  BaseDrawer,
  BaseLabel,
  BaseButton,
  BaseCard,
  BaseRadio,
  BaseCheckbox,
  BaseLoading,
  BaseInput,
  UnitDrawer,
  UnitViewDrawer,
  AddressInput,
};

export default {
  components,
  mounted() {
    const unitId = this.$route.params.unitId;
    this.getUnits(unitId);
  },
  data() {
    return {
      unit: {},
      unitName: null,
      unitAddress: null,
      showSource: {
        client_id: null,
        client_secret: null,
      },
      sourceSelected: '',
      checked: false,
      done: false,
      error: false,
      editLoading: false,
      showLoading: false,
      step: 1,
      tokens: {
        id: null,
        status: null,
        client_id: null,
        client_secret: null,
      },
      sources: [],
      availableSources: [],
      drawer: {
        open: false,
        close: false,
        loading: false,
        view: {
          open: false,
          close: false,
        },
        edit: {
          open: false,
          close: false,
        },
      },
    };
  },
  methods: {
    getSourceName(origin) {
      for (const source of this.availableSources) {
        if (source.alias == origin) {
          return source.name;
        }
      }
      return 'Desconhecida';
    },
    getFormatedUnitAddress() {
      const { address } = this.unit;

      let formattedAddress = address.address ? `${address.address}, ` : '';
      formattedAddress = formattedAddress.concat(
        address.number ? `${address.number} | ` : ''
      );
      formattedAddress = formattedAddress.concat(
        address.neighborhood ? `${address.neighborhood} - ` : ''
      );
      formattedAddress = formattedAddress.concat(
        address.city ? `${address.city}/` : ''
      );
      formattedAddress = formattedAddress.concat(
        address.state ? `${address.state} ` : ''
      );
      formattedAddress = formattedAddress.concat(
        address.cep
          ? `(${address.cep.replace(/^(\d{5})(\d)/, '$1-$2')})`
          : ''
      );

      return formattedAddress;
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = false;
    },
    openDrawerEdit() {
      this.drawer.edit.open = !this.drawer.edit.open;
      this.drawer.edit.close = false;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    async getUnits(unitId) {
      const response = await EnvironmentsAPI.getUnits();
      if (response.getData([]).length == 0) {
        this.$router.push('/base-de-dados');
      }

      for (const unit of response.getData([])) {
        if (unit.id == unitId) {
          this.unit = unit;
          this.unitName = unit.name;
          this.unitAddress = unit.address && {
            address: unit.address,
            number: unit.number,
            region: unit.neighborhood,
            city: unit.city,
            state: unit.state,
            country: unit.country,
            zipcode: unit.cep,
            geolocation: unit.address.geolocation && {
              latitude: unit.address.geolocation.latitude,
              longitude: unit.address.geolocation.longitude,
            },
          };
          break;
        }
      }

      if (!this.unit) {
        this.$router.push('/fontes');
      }

      //Carregar fontes disponíveis
      this.availableSources = (
        await EnvironmentsAPI.getAvailableSources()
      ).getData([]);

      //Carregar fontes de dados da unidade
      this.sources = (await EnvironmentsAPI.getSources(unitId)).getData([]);
    },
    async deleteUnit() {
      if (await confirm('Deseja realmente excluir esta unidade?')) {
        this.editLoading = true;
        const response = await EnvironmentsAPI.deleteUnit(this.unit.id);
        if (response.getErrors([]).length == 0) {
          this.$router.push('/fontes');
        } else {
          alert('Não foi possível excluir a unidade');
        }
        this.editLoading = false;
      }
    },
    async deleteSource(source) {
      if (await confirm('Deseja realmente excluir essa fonte de dados?')) {
        const response = await EnvironmentsAPI.deleteSource(
          this.unit.id,
          source.id
        );
        if (response.getErrors([]).length == 0) {
          await this.getUnits(this.$route.params.unitId);
        } else {
          alert('Não foi possível excluir a fonte de dados');
        }
      }
    },
    async toggleSourceStatus(source) {
      const response = await EnvironmentsAPI.setSourceStatus(
        this.unit.id,
        source.id,
        source.status == 'ACTIVATED' ? 'INACTIVATED' : 'ACTIVATED'
      );

      if (response.getErrors([]).length == 0) {
        this.getUnits(this.$route.params.unitId);
      } else {
        alert('Não foi possível alterar o status da fonte de dados');
      }
    },
    onAddressSelected({ address }) {
      console.log(address);
      this.unitAddress = address;
    },
    async save() {
      if (!this.unitName) {
        this.error = true;
        return;
      }
      this.editLoading = true;
      const response = await EnvironmentsAPI.putUnit({
        id: this.unit.id,
        name: this.unitName,
        address: this.unitAddress && {
          address: this.unitAddress.address,
          number: this.unitAddress.number,
          neighborhood: this.unitAddress.region,
          city: this.unitAddress.city,
          country: this.unitAddress.country,
          state: this.unitAddress.state,
          cep: this.unitAddress.zipcode,
          geolocation: this.unitAddress.geolocation,
        },
      });
      this.editLoading = false;

      if (response.getErrors([]).length > 0) {
        this.error = true;
        return;
      }

      this.error = false;
      this.drawer.edit.close = !this.drawer.edit.close;

      await this.getUnits(this.unit.id);
      return false;
    },
    selectUnitSource(source) {
      this.showSource = source;
      this.drawer.view.open = !this.drawer.view.open;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.sources {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sources .source {
  background: var(--box);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sources .source .logo {
  width: 50px;
  height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 10px;
  background: #333a4059;
}

.sources .source .logo img {
  max-width: 100%;
}

.sources .source .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  color: #aaaaab;
  cursor: 'pointer';
}

.tokens {
  background: #2d3338;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 35px;
}

.tokens p {
  color: #aaaaab;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}

.tokens p:not(:first-child) {
  border-top: 1px solid #333a40;
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
}

.tokens .code {
  background: #282d30;
  color: #cccccc;
  font-size: 12px;
  line-height: 14px;
  border-radius: 10px;
  opacity: 0.8;
  padding: 15px 13px;
  display: flex;
  align-items: center;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructions p {
  color: #aaaaab;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
}

.instructions a {
  color: var(--default);
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 4px;
  border-bottom: 1px solid;
}

.status-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2d3338;
  padding: 30px 15px;
  border-radius: 10px;
}

.status-import i {
  font-size: 30px;
  margin-bottom: 25px;
}

.status-import i.success {
  color: #82d997;
}

.status-import i.error {
  color: #6f767e;
}

.status-import p {
  color: #cccccc;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  opacity: 0.75;
}

.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .sources {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>
