<template>
  <div>
    <base-title
      :title="verifyEditMode() ? 'Editar Público' : 'Criar Público'"
      subtitle="Públicos"
      link="publicos"
    >
      <base-button
        v-if="!successPublic && !editSuccessPublic && verifyEditMode()"
        text="Excluir Público"
        design="two"
        :class="!!isMobile ? 'reverse mb_15 w-100' : ''"
        @click="removePublic()"
      />
    </base-title>

    <base-box v-if="successPublic">
      <div class="success-box">
        <div class="icon-base">
          <i class="far fa-check" />
        </div>
        <p>Público criado com sucesso!</p>
      </div>
    </base-box>
    <base-box v-else-if="editSuccessPublic">
      <div class="success-box">
        <div class="icon-base">
          <i class="far fa-check" />
        </div>
        <p>Público editado com sucesso!</p>
      </div>
    </base-box>
    <div class="tab-container" v-else>
      <div
        class="tab-options"
        :class="`step-${activeTab}`"
        v-show="tabStep === 'steps'"
      >
        <div
          v-for="(step, stepIndex) of getEnableStep()"
          :key="stepIndex"
          class="tab"
          :class="{
            active: activeTab === step.index,
            enabled: step.enabled,
          }"
          @click="selectTab(step.alias)"
        >
          <div class="d-flex">
            <span class="number" :class="{ done: activeTab > step.index }">
              <i
                class="fal fa-check"
                v-if="activeTab > step.index && isMobile"
              />
              <span v-else>{{ stepIndex + 1 }}</span>
            </span>

            <div class="d-flex flex-column">
              <p class="name">{{ step.description }}</p>

              <p v-if="step.subdescriptionType == 'text'" class="textMessages">
                {{ step.subdescription }}
              </p>
              <div v-else-if="step.subdescriptionType == 'tags'" class="tags">
                <div
                  v-for="(item, itemIndex) of step.subdescription"
                  :key="itemIndex"
                  class="tag"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-content"
        v-show="tabStep === (isMobile ? 'tab-content' : 'steps')"
      >
        <div class="progress-line" v-if="isMobile">
          <div class="done" :style="`width: ${50 * activeTab}%`"></div>
        </div>

        <div class="header-mobile" v-if="isMobile">
          <p>{{ getStepHeaderMobile() }}</p>
        </div>

        <div
          v-if="activeTab === STEPS.NAME_STEP"
          :key="`nameStep-${Date.now()}`"
          class="h-100"
        >
          <name-step
            :audience="audience"
            @save="(value) => setName(value, true)"
          />
        </div>

        <div
          v-if="activeTab === STEPS.SEGMENTATION_STEP"
          :key="`segmentationStep-${Date.now()}`"
          class="h-100"
        >
          <segmentation-step
            :audience="audience"
            @save="(audience) => saveAudience(audience)"
          />
        </div>
      </div>

      <div class="tab-active" v-if="isMobile">
        <p @click="tabStep = 'steps'" :class="{ active: tabStep === 'steps' }">
          Passos
        </p>
        <p
          @click="tabStep = 'tab-content'"
          :class="{ active: tabStep === 'tab-content' }"
        >
          Criação
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseDrawer from '@/components/Drawer';
import BaseBox from '@/components/Box';
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseLoading from '@/components/Loading';
import NameStep from '@/containers/Publics/Steps/NameStep';
import SegmentationStep from '@/containers/Publics/Steps/SegmentationStep';
import { AudienceAPI } from '@/api/AudienceAPI';

const components = {
  BaseButton,
  BaseTitle,
  BaseDrawer,
  BaseCard,
  BaseInput,
  BaseLoading,
  NameStep,
  SegmentationStep,
  BaseBox,
};

const STEPS = {
  NAME_STEP: 1,
  SEGMENTATION_STEP: 2,
};

export default {
  components,
  data() {
    return {
      editSuccessPublic: false,
      successPublic: false,
      tabStep: 'steps',
      activeTab: 1,
      publicName: null,
      audience: {
        name: null,
        visible: true,
        rules: [],
      },
      drawer: {
        open: false,
        close: false,
      },
      STEPS,
      tabs: {
        NAME_STEP: {
          alias: 'NAME_STEP',
          index: 1,
          description: 'Nome do público',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        SEGMENTATION_STEP: {
          alias: 'SEGMENTATION_STEP',
          index: 2,
          description: 'Segmentação',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: false,
        },
      },
    };
  },
  async mounted() {
    this.loadAudience();
    this.verifyEditMode();
  },
  methods: {
    verifyEditMode() {
      const { id } = this.$route.params;
      if (!id) {
        return false;
      }
      return true;
    },
    removePublic() {
      const { id } = this.$route.params;
      confirm(
        `Você tem certeza que deseja excluir o público? Ao fazer isso todas as configurações serão perdidas`,
        (res) => {
          if (!res) return;
          AudienceAPI.deletePublic(id).then(() => {
            alert('Público excluído com sucesso!');
            setTimeout(() => {
              this.$router.push('/publicos');
            }, 1000);
          });
        }
      );
    },
    async loadAudience() {
      if (this.$route.params.id) {
        const audienceId = this.$route.params.id;
        const audienceResponse = await AudienceAPI.getAudienceById(audienceId);
        const audience = audienceResponse.getData(null);
        if (!audience || audience.campaigns.length > 0) {
          this.$router.push({ name: 'publicos' });
          return;
        }

        this.audience.name = audience.name;
        this.audience.rules = audience.rules;
        this.audience.audience_visible = true;
        this.$forceUpdate();
      }
    },
    getStepHeaderMobile() {
      switch (this.activeTab) {
        case STEPS.NAME_STEP:
          return '1 de 2 - Nome do público';
        case STEPS.SEGMENTATION_STEP:
          return '2 de 2 - Segmentação';
      }
    },
    selectTab(tabAlias) {
      if (this.tabs[tabAlias].enabled) {
        this.activeTab = this.tabs[tabAlias].index;
        if (this.isMobile) {
          this.tabStep = 'tab-content';
        }
      }
    },
    setName(name, jumpToNext) {
      this.audience.name = name;
      this.tabs.NAME_STEP.enabled = true;
      this.tabs.SEGMENTATION_STEP.enabled = true;
      if (jumpToNext) {
        this.activeTab = STEPS.SEGMENTATION_STEP;
      }
    },
    getEnableStep() {
      const tabs = JSON.parse(JSON.stringify(this.tabs));
      return Object.values(tabs);
    },
    async saveAudience(audience) {
      if (this.$route.params.id) {
        const responseUpdate = await AudienceAPI.updateAudience(
          this.$route.params.id,
          audience
        );

        const rulesResponse = await AudienceAPI.updateAudienceRules(
          this.$route.params.id,
          audience.rules
        );

        if (
          responseUpdate.getCode(500) === 200 &&
          rulesResponse.getCode(500) === 200
        ) {
          this.editSuccessPublic = true;
          setTimeout(() => {
            this.changeRoute('publicos');
          }, 3000);
          return;
        }

        alert(responseUpdate.getErrors([])[0]);
      } else {
        const response = await AudienceAPI.createAudience(audience);
        if (response.getCode(500) === 200) {
          this.successPublic = true;
          setTimeout(() => {
            this.changeRoute('publicos');
          }, 5000);
          return;
        }

        alert(response.getErrors([])[0]);
      }
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.success-box {
  padding: 25px 20px 20px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box);
}
.success-box .icon-base {
  background-color: #4c4cff;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-box i {
  color: #fff;
  font-size: 17px;
}
.success-box p {
  color: #6161ff;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-top: 25px;
}
.tab-active {
  display: none;
}
.tab-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: var(--box);
  border-radius: 10px;
  padding: 25px;
  min-height: 500px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.header .title {
  color: #aaaaab;
  line-height: 19px;
  font-size: 16px;
  font-weight: bold;
}

.button-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  flex: 1;
  align-items: flex-end;
}

.tab-container .tab-options {
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 320px;
  position: relative;
  background: var(--box);
  border-radius: 5px;
  font-weight: 500;
}

.tab-container .tab-options .tab {
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.1s ease;
  border-radius: 10px;
}

.tab-container .tab-options .tab:not(.enabled) {
  cursor: not-allowed;
}

.tab-container .tab-options .tab .number {
  border-radius: 50%;
  border: 1px solid #6f767e59;
  font-weight: bold;
  color: #aaaaab;
  font-size: 12px;
  margin-right: 17px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #292e32;
}
.tags {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.tags .tag {
  color: #aaaaabf2;
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.tab-container .tab-options .tab .name {
  font-size: 14px;
  line-height: 17px;
  color: #999;
  margin-top: 7px;
  font-weight: 700;
}
.tab-container .tab-options .tab p:not(.name) {
  color: #aaaaabd9;
  line-height: 15px;
  font-size: 12px;
  margin-top: 10px;
}
.tab-container .tab-options .tab:not(.active).enabled:hover {
  background: #292e32;
}
.tab-container .tab-content {
  position: relative;
  width: 80%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background: var(--box);
  overflow: auto;
  margin-left: 20px;
  border-radius: 10px;
  border: 1px solid #2e3438;
}

.tab-container .tab-options .tab p:not(.name, .tag-number) {
  color: rgba(170, 170, 171, 0.7);
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}
.tab-container .tab-options .tab:not(.active).enabled:hover {
  background: #292e32;
}

@media screen and (min-width: 992px) {
  .tab-container .tab.active {
    background: #2e343873;
    border-radius: 10px;
  }
  .tab-container .tab-options .tab:not(:last-child) {
    margin-bottom: 15px;
  }
  .tab-container .tab-options .tab.active .number {
    border-color: rgba(76, 76, 255, 0.35);
    color: var(--default);
  }
}

@media screen and (max-width: 991px) {
  .button-bottom button {
    width: 100%;
  }
  .tab-container .tab-options .tab {
    min-height: 80px;
    padding: 20px;
    transition: none;
  }
  .tab-container .tab-options:after {
    content: '';
    background: rgba(111, 118, 126, 0.25);
    height: calc(100% - 60px);
    width: 1px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    left: 35px;
  }
  .tab-container .tab-options .tab .number {
    z-index: 9;
  }
  .tab-container .tab-options .tab p:not(.name) {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #aaaaabf2;
  }
  .tab-container .tab:after {
    content: '\f054';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    right: 25px;
    font-size: 12px;
    color: #8c8c8c;
  }
  .tab-container .tab-content {
    width: 100%;
    margin: 0;
    position: relative;
    padding: 20px 20px;
    border: none;
  }
  .tab-container .tab-options .tab .number.done i {
    background: #4c4cff;
    border-radius: 50%;
    color: #fff;
    width: 12px;
    height: 12px;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .progress-line,
  .progress-line > .done {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    top: 0;
  }
  .progress-line {
    border: 1px solid rgba(111, 118, 126, 0.1);
  }
  .progress-line > .done {
    background: var(--default);
  }
  .header-mobile {
    color: rgba(170, 170, 171, 0.9);
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-weight: bold;
    border-bottom: 1px solid rgba(111, 118, 126, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  .header {
    display: none;
  }
  .tab-container .tab-options {
    width: 100%;
  }
  .tab-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .tab-active {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    border-top: 1px solid rgba(111, 118, 126, 0.25);
  }
  .tab-active p {
    font-size: 14px;
    line-height: 17px;
    color: rgba(170, 170, 171, 0.85);
    font-weight: 300;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }
  .tab-active p.active {
    font-weight: bold;
    color: var(--default);
    border-color: var(--default);
  }
}
</style>
