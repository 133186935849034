<template>
  <div>
    <div v-if="finishedMount">
      <base-title
        :title="page.title"
        :subtitle="page.subtitle"
        :hasBackTo="page.hasBackTo"
        @backTo="changePage"
      >
        <base-input
          search
          :records="counter"
          placeholder="Buscar por..."
          class="mb_0 pt_0"
          design="search"
          v-if="!isMobile"
          @input="isTyping = true"
          v-model="search"
        />

        <base-button
          v-if="!isMobile && page.allowAdd"
          @click.native="changeRoute('criar-campanha')"
          :class="isMobile ? 'reverse w-100 mb_15' : 'ml_15'"
          >Adicionar campanha</base-button
        >

        <base-button
          v-if="page.allowFilter"
          @click="drawer.filter.open = !drawer.filter.open"
          class="m_auto filter"
          :class="isMobile ? 'reverse w-100' : 'ml_15'"
          design="two"
        >
          <p class="filter" v-if="!isMobile">
            <i class="far fa-sliders-h"></i>
          </p>
          <p class="filter" v-else>Filtrar período</p>
        </base-button>

        <base-button
          v-if="page.hasOptions"
          design="two"
          :class="isMobile ? 'reverse w-100 mt_15' : 'ml_15'"
          @click="openDrawerOptions()"
        >
          <p v-if="!isMobile">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </p>
          <p v-else>Opções</p>
        </base-button>
      </base-title>

      <div class="create-campaign" v-if="!completedCampaigns">
        <i class="fal fa-megaphone" />
        <h1>Crie sua campanha</h1>
        <p>
          Através desta ferramenta, será possível realizar os disparos das suas
          campanhas.
        </p>
        <div class="btn-create">
          <base-button
            text="Criar campanha"
            class="big w-100"
            @click="changeRoute('criar-campanha')"
          />
        </div>
      </div>

      <div v-if="completedCampaigns">
        <div class="campaigns">
          <campaign-box
            @changeStatus="changeStatusHandler"
            v-for="(campaign, index) in campaigns"
            :key="index"
            :campaign="campaign"
          />
        </div>
      </div>

      <base-drawer
        title="Filtrar por"
        :open="drawer.filter.open"
        :close="drawer.filter.close"
        v-show="page.filters && page.filters.length > 0"
      >
        <base-card>
          <base-label text="Status de campanha" class="mb_20" />
          <base-checkbox @click="formatterQuery('DRAFT')" class="mb_10"
            >Rascunho</base-checkbox
          >
          <base-checkbox @click="formatterQuery('ACTIVE')" class="mb_10"
            >Ativa</base-checkbox
          >
          <base-checkbox @click="formatterQuery('SCHEDULED')" class="mb_10"
            >Agendada</base-checkbox
          >
          <base-checkbox @click="formatterQuery('FINISHED')" class="mb_10"
            >Finalizada</base-checkbox
          >
        </base-card>

        <template slot="footer">
          <base-button @click="handleFilter" class="w-100 big"
            >Salvar</base-button
          >
        </template>
      </base-drawer>

      <base-drawer
        title="Opções"
        :open="drawer.options.open"
        :close="drawer.options.close"
      >
        <base-card>
          <div class="grid-options">
            <div class="option" @click="filterType(pages.PAUSED)">
              <i class="fal fa-pause" />
              <p>Ver campanhas pausadas</p>
            </div>

            <div class="option" @click="filterType(pages.ARCHIVED)">
              <i class="fal fa-box-archive" />
              <p>Ver campanhas arquivadas</p>
            </div>
          </div>
        </base-card>
      </base-drawer>

      <infinite-loading @infinite="loadCampaigns">
        <span slot="no-more"> </span>
        <span slot="spinner">
          <base-loading />
        </span>
        <span slot="no-results">
          <base-empty
            v-if="!campaigns || (campaigns && campaigns.length === 0)"
            title="Você ainda não tem campanhas cadastradas."
            message="Todas as campanhas irão aparecer aqui."
          >
            <template slot="image">
              <i class="far fa-bullhorn" />
            </template>
          </base-empty>
        </span>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseEmpty from '@/components/Empty';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseCheckbox from '@/components/Checkbox';
import PerformanceApi from '@/api/modules/performance';
import BaseLoading from '@/components/Loading';
import Utils from '@/utils';
import { EnvironmentAPI } from '@/api/Environment';

import CampaignBox from '@/containers/Modules/Performance/CampaignBox';

const OptionsByPage = {
  PAUSED: {
    type: 'PAUSED',
    title: 'Campanhas pausadas',
    subtitle: 'Campanhas',
    filters: ['PAUSED'],
    allowAdd: false,
    allowFilter: false,
    hasOptions: false,
    hasBackTo: true,
  },
  ARCHIVED: {
    type: 'ARCHIVED',
    title: 'Campanhas arquivadas',
    subtitle: 'Campanhas',
    filters: ['ARCHIVED'],
    allowAdd: false,
    allowFilter: false,
    hasOptions: false,
    hasBackTo: true,
  },
  MAIN: {
    type: 'MAIN',
    title: 'Campanhas',
    subtitle: null,
    filters: [
      'UNDER_ANALYSIS',
      'DRAFT',
      'ACTIVE',
      'SCHEDULED',
      'PROCESSING',
      'FINISHED',
    ],
    allowAdd: true,
    allowFilter: true,
    hasOptions: true,
    hasBackTo: false,
  },
};

export default {
  data() {
    return {
      pages: {
        MAIN: 'MAIN',
        PAUSED: 'PAUSED',
        ARCHIVED: 'ARCHIVED',
      },
      page: OptionsByPage.MAIN,
      OptionsByPage,
      pagination: {
        next: 1,
        limit: 18,
      },
      arrayQuerys: [],
      counter: 0,
      campaigns: [],
      finishedMount: true,
      activeCampaigns: [],
      filteredCampaigns: [],
      contentLoading: true,
      search: '',
      queryType: {
        statuses: OptionsByPage.MAIN.filters,
      },
      queryStatus: [],
      offset: 1,
      selectedTab: 0,
      isTyping: false,
      count: 0,
      drawer: {
        filter: {
          open: false,
          close: false,
        },
        options: {
          open: false,
          close: false,
        },
      },
      query: [],
      type: [],
      completedCampaigns: [],
    };
  },
  components: {
    BaseButton,
    BaseTitle,
    BaseLabel,
    BaseInput,
    BaseEmpty,
    BaseDrawer,
    BaseCard,
    BaseCheckbox,
    InfiniteLoading,
    BaseLoading,
    CampaignBox,
  },
  async created() {
    this.offset++;
  },

  methods: {
    changePage() {
      this.filterType(this.pages.MAIN);
    },

    changeStatusHandler({ id, oldValue, newValue }) {
      const campaignIndex = this.campaigns.findIndex(
        (campaign) => campaign.id === id
      );

      if (campaignIndex >= 0) {
        const shouldBeRemoved =
          oldValue === 'PAUSED' &&
          oldValue !== newValue &&
          this.page.type === 'PAUSED';
        if (shouldBeRemoved) {
          this.campaigns.splice(campaignIndex, 1);
        } else {
          this.campaigns[campaignIndex].status = newValue;
        }
      }
    },

    searchItem() {
      const { search } = this;
      if (search.length > 0) {
        this.queryType.name = search;
        this.pagination.next = 1;
        this.loadCampaigns();
      } else {
        this.queryType = {
          statuses: this.page.filters,
        };
        this.pagination.next = 1;
        this.loadCampaigns();
      }
    },

    async loadCampaigns($state) {
      const { pagination } = this;

      if (this.pagination.next == -1) {
        $state && $state.complete();
        return;
      }

      try {
        const campaingsResponse = await EnvironmentAPI.getCampaigns(
          pagination.next,
          pagination.limit,
          this.queryType
        );
        const campaigns = campaingsResponse.getData({ items: [] });
        this.counter = campaigns.length;

        if (pagination.next === 1) {
          this.campaigns = campaigns.items;
        } else {
          this.campaigns.push(...campaigns.items);
        }

        this.pagination.next = campaigns.next.page || -1;

        if (this.pagination.next != -1) {
          $state && $state.loaded();
        } else {
          $state && $state.complete();
        }
      } catch (err) {
        console.log(err);
      }
    },

    openDrawerOptions() {
      this.drawer.options.open = !this.drawer.options.open;
    },

    async formatterQuery(data) {
      if (!this.arrayQuerys.includes(data)) {
        this.arrayQuerys.push(data);
        this.queryType.statuses = this.arrayQuerys;
      } else {
        this.arrayQuerys.splice(this.arrayQuerys.indexOf(data), 1);
        // this.queryType.statuses = this.arrayQuerys;
      }

      // if (type === 'type' && !this.queryType.includes(data)) {
      //   this.queryType.push(data);
      // } else if (this.queryType.includes(data)) {
      //   this.queryType.splice(this.queryType.indexOf(data), 1);
      // }

      // if (type === 'status' && !this.queryStatus.includes(data)) {
      //   this.queryStatus.push(data);
      // } else if (this.queryStatus.includes(data)) {
      //   this.queryStatus.splice(this.queryStatus.indexOf(data), 1);
      // }
    },

    async handleFilter() {
      this.pagination.next = 1;
      if (this.queryType.statuses.length === 0) {
        this.queryType.statuses = OptionsByPage.MAIN.filters;
      }
      this.loadCampaigns();
      this.drawer.filter.close = this.drawer.filter.open;
    },

    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },

    filterType(newPage) {
      this.queryType.statuses = this.OptionsByPage[newPage].filters;
      this.pagination.next = 1;
      this.loadCampaigns();
      this.page = this.OptionsByPage[newPage];
      this.drawer.options.close = this.drawer.options.open;
    },

    getStatusBySelectedTab() {
      return this.selectedTab === 0 ? 'ACTIVE' : 'FINISHED';
    },
  },
  watch: {
    search: Utils.debounce(function () {
      this.isTyping = false;
    }, 1000),

    isTyping(value) {
      this.loading = true;
      if (!value) {
        this.searchItem();
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.create-campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.create-campaign i {
  color: #8c8c8c99;
  font-size: 70px;
  margin-bottom: 25px;
}
.create-campaign h1 {
  color: var(--label-secondary);
  font-size: 20px;
  line-height: 24px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 25px;
}
.create-campaign p {
  color: #aaaaabbf;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 35px;
}
.btn-create {
  width: 185px;
}
.balance {
  color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
}

.balance .value {
  border: 1px solid #0dc59d73;
  background: #0dc59d14;
  border-radius: 5px;
  padding: 12px 35px;
  line-height: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #0dc59d;
  font-family: 'Lato';
}

.balance .value span {
  font-weight: 900;
  color: #0ed9ad;
}

@media screen and (max-width: 991px) {
  .create-campaign i {
    color: #8c8c8c99;
    font-size: 55px;
    margin-bottom: 20px;
  }
  .create-campaign h1 {
    margin-bottom: 15px;
  }
  .create-campaign p {
    line-height: 20px;
    margin-bottom: 25px;
    text-align: center;
  }
  .balance .value {
    padding: 10px;
  }
}

.tabs ul li {
  display: inline;
  text-align: center;
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 6px;
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  color: #999999ad;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

.campaigns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0 25px;
}

.occurrences {
  background: var(--box-up);
  padding: 4px 12px;
  margin-left: 10px;
  border-radius: 5px;
  color: #aaaaaae6;
  font-size: 14px;
  line-height: 17px;
  position: relative;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .campaigns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 25px 15px;
  cursor: pointer;
}

.grid-options .option:hover i {
  transform: scale(1.2);
  transition: all 0.2s ease;
  color: var(--default);
}

.grid-options .option i {
  color: #999999;
  font-size: 20px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
}

.grid-options .option p {
  color: #888888cc;
  font-size: 12px;
  line-height: 15px;
}
</style>
