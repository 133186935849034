<template>
  <div>
    <base-title title="Unidades" @addAction="openDrawer('unity')" showButtonAdd>
      <base-button
        text="Ordernação de fontes"
        design="two"
        :class="isMobile ? 'w-100' : 'mr_15'"
        @click="openDrawer('ordernation')"
      />

      <base-button
        v-if="!isMobile"
        text="Adicionar unidade"
        @click="openDrawer('unity')"
      />
    </base-title>

    <base-empty
      v-if="!units.length"
      title="Você ainda não possui unidades"
      message="Através desta ferramenta, será possível cadastrar suas unidades"
      buttonText="Cadastrar unidade"
      @onClick="openDrawer('unity')"
    >
      <template slot="image">
        <i class="far fa-share-nodes" />
      </template>
    </base-empty>

    <div class="units" v-else>
      <div
        class="unity-box"
        v-for="(unity, i) in units"
        :key="i"
        @click="changeRoute('unidade', { unitId: unity.id })"
      >
        <p>{{ unity.name }}</p>
        <div class="inputs">
          <span>{{ unity.countSources }} fontes</span>
        </div>
        <i class="far fa-chevron-right" />
      </div>
    </div>

    <base-drawer
      :open="drawer.ordernation.open"
      :close="drawer.ordernation.close"
      title="Ordenação de fontes"
    >
      <base-tabs :tabs="modeTabs" :tabActived="tabActive" @change="changeTab" />
      <div v-if="savingPriority" class="loading">
        <base-loading color="#6161ff" />
      </div>
      <base-card v-else>
        <div
          class="source-box"
          v-for="(item, i) in priority[modeTabs[tabActive].alias]"
          :key="i"
        >
          <p>{{ getSourceName(item.source) }}</p>
          <div class="inputs">
            <span>{{ getUnitName(item.unit) }}</span>
          </div>
          <div class="chevrons">
            <i
              class="far fa-chevron-up"
              v-if="i != 0"
              @click="changePosition(modeTabs[tabActive].alias, i, i - 1)"
            />
            <i
              class="far fa-chevron-down"
              v-if="i != priority[modeTabs[tabActive].alias].length - 1"
              @click="changePosition(modeTabs[tabActive].alias, i, i + 1)"
            />
          </div>
        </div>
      </base-card>

      <template slot="footer">
        <base-button
          class="w-100 big"
          :disabled="savingPriority || !hasPriorityChanges"
          @click="savePriority()"
          >Salvar</base-button
        >
      </template>
    </base-drawer>

    <base-drawer
      title="Cadastre uma unidade"
      :open="drawer.unity.open"
      :close="drawer.unity.close"
      @onClose="closeDrawer('unity')"
    >
      <base-card>
        <base-label text="Insira o nome da unidade" class="mb_0" />
        <base-input
          v-model="unityName"
          :valid="!error"
          @focus="error = false"
        />
        <base-message v-if="error" design="red">
          <span>Preencha corretamente o nome da unidade</span>
        </base-message>
      </base-card>
      <base-card>
        <base-label text="Insira o endereço da unidade" class="mb_0"/>
        <address-input
          placeholder="Buscar endereço e número"
          @selectedAddress="onAddressSelected"
        />
      </base-card>

      <template slot="footer">
        <base-button class="w-100" @click="save()">Salvar</base-button>
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EnvironmentsAPI } from '@/api/Environments';

import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import BaseDrawer from '@/components/Drawer';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';
import BaseMessage from '@/components/Message';
import BaseTabs from '@/components/Tabs';
import BaseLoading from '@/components/Loading';
import AddressInput from '@/containers/AddressInput';

const components = {
  BaseTitle,
  BaseEmpty,
  BaseDrawer,
  BaseLabel,
  BaseInput,
  BaseButton,
  BaseCard,
  BaseMessage,
  BaseLoading,
  BaseTabs,
  AddressInput,
};

export default {
  components,
  created() {
    this.getUnits();
  },
  mounted() {
    this.getPriorities();
    this.getAvaliableSources();
  },
  data() {
    return {
      unityName: '',
      unityAddress: null,
      error: false,
      units: [],
      savingPriority: false,
      hasPriorityChanges: false,
      availableSources: [],
      priority: {},
      drawer: {
        ordernation: {
          open: false,
          close: false,
        },
        unity: {
          open: false,
          close: false,
        },
      },
      tabActive: 0,
      modeTabs: [
        { name: 'Nome', value: 0, alias: 'name' },
        { name: 'E-mail', value: 1, alias: 'email' },
        { name: 'Aniversário', value: 2, alias: 'birthdate' },
      ],
    };
  },
  methods: {
    async getUnits() {
      const response = await EnvironmentsAPI.getUnits();
      if (response.getData([]).length > 0) {
        this.units = response.getData([]);
      }
    },
    async getAvaliableSources() {
      this.availableSources = (
        await EnvironmentsAPI.getAvailableSources()
      ).getData([]);
    },
    async getPriorities() {
      const response = await EnvironmentsAPI.getConfigurations();
      if (response.getData({})) {
        const priorities = response.getData({ priority: [] }).priority;
        for (const priority of priorities) {
          this.priority[priority.field] = priority.sourcesPriority;
        }
      }
    },
    getUnitName(unitId) {
      const unit = this.units.find((unit) => unit.id == unitId);
      return `Unidade ${unit ? unit.name : 'desconhecida'}`;
    },
    getSourceName(origin) {
      for (const source of this.availableSources) {
        if (source.alias == origin) {
          return source.name;
        }
      }
      return 'Fonte Desconhecida';
    },
    changePosition(alias, itemIndex, otherItemIndex) {
      this.hasPriorityChanges = true;
      const item = this.priority[alias][itemIndex];
      const otherItem = this.priority[alias][otherItemIndex];

      const currentPosition = item.position;
      item.position = otherItem.position;
      otherItem.position = currentPosition;

      this.priority[alias] = this.priority[alias].sort(
        (a, b) => a.position - b.position
      );
      this.$forceUpdate();
    },
    openDrawer(drawer) {
      this.drawer[drawer].open = !this.drawer[drawer].open;
    },
    closeDrawer(drawer) {
      this.drawer[drawer].open = !this.drawer[drawer].open;
      this.drawer[drawer].close = !this.drawer[drawer].close;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    changeTab(tab) {
      this.tabActive = tab;
    },
    onAddressSelected({ address }) {
        this.unityAddress = address;
    },
    async save() {
      if (!this.unityName) {
        this.error = true;
        return;
      }

      const response = await EnvironmentsAPI.postUnits({
        name: this.unityName,
        address: this.unityAddress && {
          address: this.unityAddress.address,
          number: this.unityAddress.number,
          neighborhood: this.unityAddress.region,
          city: this.unityAddress.city,
          country: this.unityAddress.country,
          state: this.unityAddress.state,
          cep: this.unityAddress.zipcode,
          geolocation: this.unityAddress.geolocation
        }
      });

      if (response.getErrors([]).length > 0) {
        this.error = true;
        return;
      }

      this.error = false;
      this.unityName = '';
      this.closeDrawer('unity');

      await this.getUnits();
      return false;
    },
    async savePriority() {
      this.savingPriority = true;
      const newPriorities = Object.entries(this.priority).map(
        ([field, sourcesPriority]) => ({ field, sourcesPriority })
      );
      const response = await EnvironmentsAPI.putConfigurations(
        { priority: newPriorities },
        false
      );
      this.hasPriorityChanges = false;
      this.savingPriority = false;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.units {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.source-box {
  background: #2e3438;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.unity-box {
  background: #252a2e;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.unity-box:hover {
  cursor: pointer;
  background: #2a2f33;
}
:is(.unity-box, .source-box) p {
  color: #aaaaab;
  font-size: 16px;
  line-height: 19px;
}
:is(.unity-box, .source-box) .inputs {
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
}
.unity-box .inputs {
  background: #2d3338;
}
.source-box .inputs {
  background: #353d42;
}
:is(.unity-box, .source-box) .inputs span {
  color: #aaaaab;
  opacity: 0.85;
  font-size: 12px;
  line-height: 14px;
}
:is(.unity-box) i {
  color: #6f767e;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
:is(.unity-box, .source-box) .chevrons {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
:is(.unity-box, .source-box) .chevrons i:last-child {
  margin-left: 20px;
}
:is(.unity-box, .source-box) .chevrons i:hover {
  opacity: 0.8;
}

.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .units {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
