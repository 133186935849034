<script>
const TABS = { CUSTOM: 0, SUGGESTED: 1 };

export default {
  props: {
    selectedTab: { type: Number, default: 0 },
  },
  render(h) {
    const { selectedTab } = this.$props;
    const tabs = [
      { key: TABS.CUSTOM, label: 'Personalizados' },
      { key: TABS.SUGGESTED, label: 'Sugeridos' },
    ];

    return (
      <div class='tabs-publics'>
        <ul>
          {tabs.map((tab, tabIndex) => (
            <li key={tabIndex}>
              <a
                onClick={() => this.$emit('onTabChange', tab.key)}
                class={`${tab.key === selectedTab ? 'active' : ''}`}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  },
};
</script>
<style>
.tabs-publics {
  margin-bottom: 40px;
}

.tabs-publics ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}

.tabs-publics a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}

.tabs-publics a.active {
  color: var(--default);
  border-color: var(--default);
}
</style>
