<template>
  <div>
    <base-title
      :title="campaign.name"
      :subtitle="StringLanguage.campaign"
      link="performance"
      @addAction="openDrawer()"
      :showButtonAdd="selectedTab === 1"
    >
      <base-button
        v-if="func()"
        text="Pausar campanha"
        design="two"
        :class="!!isMobile ? 'reverse mb_15 w-100' : 'mr_15'"
        @click="pauseCampaign(campaign.name, campaign.id)"
      />
      <base-button
        v-if="func()"
        text="Arquivar campanha"
        design="two"
        :class="!!isMobile ? 'reverse mb_15 w-100' : 'mr_15'"
        @click="archiveCampaign(campaign.name, campaign.id)"
      />

      <!-- <base-button
        v-if="selectedTab === 0"
        @click="drawer.filter.open = !drawer.filter.open"
        class="m_auto filter"
        :class="{ ['reverse w-100']: !!isMobile }"
        design="two"
      >
        <p class="filter" v-if="!isMobile">
          <i class="fal fa-sliders-h"></i>
        </p>
        <p class="filter" v-else>Filtrar período</p>
      </base-button> -->

      <base-input
        search
        placeholder="Buscar por..."
        class="mb_0 pt_0 mr_15"
        design="search"
        v-if="!isMobile && selectedTab === 1"
        @input="isTyping = true"
        v-model="search"
      />

      <base-button v-if="!isMobile && selectedTab === 1" @click="openDrawer()">
        Adicionar criativo
      </base-button>
    </base-title>

    <div class="tabs">
      <ul>
        <li>
          <a @click="selectedTab = 0" :class="{ active: selectedTab === 0 }">
            Detalhes da campanha
          </a>
        </li>
        <li>
          <a @click="selectedTab = 1" :class="{ active: selectedTab === 1 }">
            Criativos
          </a>
        </li>
      </ul>
    </div>

    <div class="wrapper" v-show="selectedTab === 0">
      <base-box class="details-campaign">
        <div class="campaign-name">
          <p>Resumo da campanha</p>
        </div>
        <div class="step">
          <p>Gatilho</p>
          <!-- Resolver para quando for usar i18n -->
          <div class="tags">
            <div
              class="tag"
              v-for="(segmentation, index) in buildTriggerTags(campaign)"
              :key="index"
            >
              {{ segmentation }}
            </div>
          </div>
        </div>

        <div v-if="type === 'RE'" class="step">
          <p>Disparo</p>
          <div class="tags">
            <div
              class="tag"
              v-for="(segmentation, index) in buildFiringTags(campaign)"
              :key="index"
            >
              {{ segmentation }}
            </div>
          </div>
        </div>

        <div class="step">
          <p>Método de envio</p>
          <p>SMS</p>
        </div>

        <div class="step">
          <p>Segmentação</p>
          <div class="tags">
            <div
              class="tag"
              v-for="(segmentation, index) in segmentations"
              :key="index"
            >
              {{ segmentation }}
            </div>
          </div>
        </div>

        <div class="step">
          <p>Análise</p>
          <p>{{ analysis }}</p>
        </div>
      </base-box>

      <div class="campaign-infos">
        <div class="totalizer">
          <div class="card">
            <p class="value">
              {{ Utils.convertMoney(report.conversions_total) }}
            </p>
            <p>Valor em vendas</p>
          </div>
          <div class="card">
            <p class="value">
              {{ Utils.convertMoney(report.conversions_average) }}
            </p>
            <p>Ticket médio</p>
          </div>
          <div class="card">
            <p class="value">
              {{
                report.conversions_cost === null
                  ? 'Em análise'
                  : Utils.convertMoney(report.conversions_cost)
              }}
            </p>
            <p>Custo por conversão</p>
          </div>
          <div class="card">
            <p class="value">
              {{
                (report.gross_income && report.gross_income.toFixed(2)) || 0
              }}x
              <i class="fa-solid fa-arrow-trend-up"></i>
            </p>
            <p>Retorno bruto</p>
          </div>
        </div>

        <div class="infos">
          <div class="card">
            <p>Envios</p>
            <p>{{ report.sent }}</p>
          </div>
          <div class="line"></div>
          <div class="card">
            <p>Cliques</p>
            <p>{{ report.clicks }}</p>
          </div>
          <div class="line"></div>
          <div class="card">
            <p>Conversões</p>
            <p>{{ report.conversions_quantity }}</p>
          </div>
        </div>

        <chart-box
          v-if="!isMobile"
          title="Usos por dia"
          type="bar"
          :key="charts.campaignConversions.id"
          :reloadChart="reloadChart.campaignConversions"
          :chartData="charts.campaignConversions.data"
          :chartOptions="charts.campaignConversions.options"
          :style="'height: 600px'"
        />
      </div>
    </div>

    <div v-if="selectedTab === 1" class="creatives">
      <creatives
        :campaignId="campaign.id"
        :savedItems="savedCreatives"
        :searchString="search"
        :isTyping="isTyping"
        @setIsTyping="setIsTyping"
        @popSavedItem="popSavedItem"
      />

      <creative-form
        :open="drawer.add.open"
        :close="drawer.add.close"
        @onClose="closeDrawer"
        @save="save"
        :campaignId="campaign.id"
      />
    </div>

    <filter-period
      :open="drawer.filter.open"
      :close="drawer.filter.close"
      @filter="filterPeriod"
      :allFilter="true"
      :maxCustomRange="0"
      :defaultOption="filterType"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import getDate from '@/mixins/getDate.js';
import buildTagsHelper from '@/mixins/buildTagsHelper.js';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseRadio from '@/components/Radio';
import BaseBox from '@/components/Box';
import BaseMessage from '@/components/Message';
import BaseDrawer from '@/components/Drawer';
import BaseTextarea from '@/components/Textarea';
import BaseSteps from '@/components/Steps';
import BaseCard from '@/components/Card';
import BaseLoading from '@/components/Loading';

import ChartBox from '@/containers/ChartBox';
import FilterPeriod from '@/containers/FilterPeriod';
import { EnvironmentAPI } from '@/api/Environment';
import Utils from '@/utils';

import CheckCampaign from '../../plugins/core/schema/campaignsTypes';
import Creatives from '@/containers/Modules/Performance/Creatives';
import CreativeForm from '@/containers/Modules/Performance/CreativeForm';

const components = {
  BaseButton,
  BaseTitle,
  BaseLabel,
  BaseInput,
  BaseRadio,
  BaseDrawer,
  BaseTextarea,
  BaseSteps,
  BaseCard,
  BaseLoading,
  BaseBox,
  ChartBox,
  BaseMessage,
  FilterPeriod,
  Creatives,
  CreativeForm,
};

const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

const FIRING_TYPE = {
  CR: 'Conversões recorrentes',
  CU: 'Conversão única',
  RM: 'Recorrente no mês',
  RS: 'Recorrente na semana',
  PN: 'Pontual',
};

const SEGMENT_NAME = {
  behavioralDaysOff: 'Dias sem pedir',
  behavioralBirthday: 'Aniversariante',
  clientAgeRange: 'Faixa etária',
  clientRegisterTimer: 'Tempo de cadastro',
  clientAverageTicket: 'Ticket médio',
  clientOrderQuantity: 'Quantidade de conversões',
  clientTotalSpent: 'Total gasto',
  geographicDistrict: 'Região',
  regionRange: 'Região',
  weatherPhotography: 'Fotografia climática',
};

export default {
  mixins: [getDate, buildTagsHelper],
  components,
  data() {
    return {
      campaign: {
        id: -1,
      },
      report: {},
      reloadChart: {
        campaignConversions: false,
      },
      charts: {
        campaignConversions: {
          id: Math.round(Math.random() * 1000),
          data: {
            labels: [...Array.from({ length: 30 }, (_, i) => i + 1)],
            datasets: [
              {
                label: 'Usos',
                data: Array.from({ length: 30 }, () => 0),
                backgroundColor: '#82D997',
                borderColor: '#82D997',
                borderRadius: 30,
                borderSkipped: false,
                stack: 'Stack 0',
              },
            ],
          },
          options: {
            cutoutPercentage: 62.5,
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              display: false,
              fullWidth: false,
            },
            barThickness: 5,
            /* scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              y: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
            }, */
          },
        },
      },
      selectedTab: 0,
      period: [new Date('2000-01-01'), new Date()],
      noPeriod: true,
      filterType: 5,
      drawer: {
        filter: {
          open: false,
          close: false,
        },
        add: {
          open: false,
          close: false,
        },
      },
      showLoading: false,
      savedCreatives: [],
      search: '',
      isTyping: false,
      Utils,
    };
  },
  async created() {
    await this.loadCampaign();

    this.selectedTab = this.$route.params.creatives ? 1 : 0;
  },
  methods: {
    func() {
      const isCampaignDetailTab = this.selectedTab === 0;
      const allowPause =
        this.campaign.config && (this.campaign.config || []).includes('pause');
      return isCampaignDetailTab && allowPause;
    },
    save(creative) {
      this.savedCreatives.push(creative);
    },
    popSavedItem() {
      this.savedCreatives.pop();
    },
    setIsTyping(value) {
      this.isTyping = value;
    },

    openDrawer() {
      this.drawer.add.open = !this.drawer.add.open;
    },
    closeDrawer() {
      this.drawer.add.open = !this.drawer.add.open;
      this.drawer.add.close = !this.drawer.add.close;
    },

    pauseCampaign(name, id) {
      confirm(`Deseja mesmo pausar a campanha ${name} ?`, (res) => {
        if (!res) return;
        EnvironmentAPI.putCampaignStatusById(id, 'PAUSED').then(() => {
          this.$router.push({ name: 'performance' });
        });
      });
    },

    archiveCampaign(name, id) {
      confirm(`Deseja mesmo arquivar a campanha ${name} ?`, (res) => {
        if (!res) return;
        EnvironmentAPI.putCampaignStatusById(id, 'ARCHIVED').then(() => {
          this.$router.push({ name: 'performance' });
        });
      });
    },

    async loadCampaign() {
      const { id } = this.$route.params;
      const response = await EnvironmentAPI.getCampaignById(id);
      this.campaign = response.getData({});

      const reportResponse = await EnvironmentAPI.getCampaignReportById(id);
      const report = reportResponse.getData({});
      this.report = report;

      this.charts.campaignConversions.data.datasets[0].data =
        this.report.conversions.map((conversion) => conversion.value);

      this.charts.campaignConversions.id = Math.round(Math.random() * 1000);

      this.reloadChart.campaignConversions = true;

      const getFormatterCampaign = new CheckCampaign(this.campaign);
      this.campaign.config =
        getFormatterCampaign.CampaignProcess().config || [];
    },

    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    filterPeriod(filter = false) {
      this.page = 1;

      if (filter) {
        this.noPeriod = false;
        this.filterType = filter.type;
        this.period[0] = new Date(filter.periods[0]);
        this.period[1] = new Date(filter.periods[1]);

        const date = this.period[0];

        const dateRange = `Das ${this.formatDate(
          this.period[0]
        )}, às ${this.formatDate(this.period[1])}`;

        switch (Number(filter.type)) {
          case 1:
            this.date = `Dia ${this.getDay(date)}, ${this.getMonth(
              date
            )} - ${this.getYear(date)}`;
            break;
          case 2:
            this.date = `${this.getMonth(date)} - ${this.getYear(date)}`;
            break;
          case 3:
            this.date = `Ano de ${this.getYear(date) + 1}`;
            break;
          case 4:
            this.date = dateRange;
            break;
          default:
            this.date = '';
            break;
        }
      }

      this.drawer.filter.close = !this.drawer.filter.close;
    },
    removePeriod() {
      this.noPeriod = true;
      this.period = [new Date('1900-01-01'), new Date()];
    },
    formatDate(date) {
      date = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ[Z]');

      const ds = date.split('T');
      return dayjs(dayjs(`${ds[0]} ${ds[1].split('-')[0]}`)).format(
        'HH:mm [de] DD/MM/YYYY'
      );
    },
    buildSegmentationTags(segmentations = []) {
      let segments = [];
      segmentations.forEach((s) => {
        const { value, type: field } = s;

        if (value) {
          if (value.min || value.max) {
            segments.push(`${SEGMENT_NAME[field]} (${value.min}-${value.max})`);
          } else if (field === SEGMENT_NAME[field]) {
            segments.push(`${value.address}`);
          } else if (value.minimum) {
            segments.push(`${SEGMENT_NAME[field]} +(${value.minimum})`);
          } else if (value.exact) {
            segments.push(`${SEGMENT_NAME[field]} (${value.exact})`);
          } else if (field === 'weatherPhotography') {
            segments = segments.concat(this.buildMultipleTags(value, field));
          } else if (field == 'regionRange') {
            const configs = value;
            segments.push(...configs.map((s) => {
                if(s.cities.length == 0) {
                    return s.state;
                } else if(s.cities.length == 1) {
                    const neighborhoods = s.cities[0].neighborhoods.length > 0 ? ` (${s.cities[0].neighborhoods.length})` : '';
                    return `${s.cities[0].city} - ${s.state}${neighborhoods}`;
                } else {
                    return `${s.state} (${s.cities.length})`;
                }
            }));            
          } else {
            segments.push(`${SEGMENT_NAME[field]}`);
          }

          if (value.isLast && value.field) {
            switch (value.field) {
              case '1':
                segments.push(`No(s) último(s) ${value.isLast} dia(s)`);
                break;
              case '2':
                segments.push(`No(s) último(s) ${value.isLast} mes(es)`);
                break;
              case '3':
                segments.push(`No(s) último(s) ${value.isLast} ano(s)`);
                break;
            }
          }
        } else {
          segments.push(`${SEGMENT_NAME[field]}`);
        }
      });
      return segments;
    },
    buildMultipleTags(value, type) {
      if (type !== 'weatherPhotography') return [];

      const configs = value;
      if (!configs) return [];

      let tags = [];

      if (configs.temperature)
        tags.push(
          `Temperatura (${configs.temperature.min} - ${configs.temperature.max})`
        );

      if (configs.feelslike)
        tags.push(
          `Sensação (${configs.feelslike.min} - ${configs.feelslike.max})`
        );

      if (configs.moisture)
        tags.push(
          `Umidade (${configs.moisture.min} - ${configs.moisture.max})`
        );

      if (configs.weather) {
        const featureTags = configs.weather.map((feature) => {
          if (feature === 'CLEAR') return 'Ensolarado';
          if (feature === 'RAIN') return 'Chovendo';
          if (feature === 'CLOUDY') return 'Nublado';
          if (feature === 'PARTLY_CLOUDY') return 'Parcialmente nublado';
          return '';
        });
        tags = tags.concat(featureTags);
      }

      if (configs.quantity) tags.push(`${configs.quantity.exact} Pedidos`);

      return tags;
    },
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),

    allowPause() {
      const isCampaignDetailTab = this.selectedTab === 0;
      const allowPause =
        this.campaign.config && (this.campaign.config || []).includes('pause');
      return isCampaignDetailTab && allowPause;
    },

    allowArchive() {
      const isCampaignDetailTab = this.selectedTab === 0;
      const allowArchive =
        this.campaign.config && this.campaign.config.includes('archive');
      return isCampaignDetailTab && allowArchive;
    },

    type() {
      const { type } = this.campaign;
      return type;
    },

    analysis() {
      const analyses = {
        1: 'Não realizar disparo inicial e pular análise',
        2: 'Considerar usuários mais recentes',
        4: 'Considerar toda a história',
      };

      const analysis = analyses[this.campaign.initial_flag];

      return analysis || '';
    },

    segmentations() {
      const rules = this.campaign.audience_rules || [];
      const descriptions = this.buildSegmentationTags(rules);
      return descriptions;
    },
  },
};
</script>
<style scoped>
.campaign-infos {
  display: flex;
  flex-direction: column;
}
.details-campaign {
  border-radius: 10px;
}
.details-campaign .campaign-name {
  color: rgba(170, 170, 171, 0.85);
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #363e42;
  background: #272c30;
  border-radius: 10px;
  padding: 20px;
}
.details-campaign .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}
.details-campaign .step:not(:last-child) {
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}
.details-campaign .step:last-child {
  padding-bottom: 10px;
}
.details-campaign .step p:nth-child(1) {
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
  margin-bottom: 15px;
}
.dashboard-campaign {
  background: var(--box);
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
}
.details-campaign .step p:nth-child(2) {
  color: rgba(190, 190, 191, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}
.totalizer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.totalizer .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: var(--box);
  border-radius: 10px;
  padding: 25px 25px 10px;
}
.totalizer .card i {
  color: var(--default);
  font-size: 14px;
  margin-left: 5px;
}
.totalizer .card .value {
  color: #cccccc;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}
.totalizer .card p:nth-child(2) {
  color: rgba(204, 204, 204, 0.4);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--box-up);
}
.campaign-infos .infos {
  display: flex;
  align-items: center;
  background: var(--box);
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}
.campaign-infos .infos .card {
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #292e32;
  padding: 10px 20px;
}
.campaign-infos .infos .card p:nth-child(1) {
  color: rgba(204, 204, 204, 0.55);
  border-right: 1px solid #34393e;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}
.campaign-infos .infos .card p:nth-child(2) {
  color: #cccccc;
  border-bottom: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
}
.campaign-infos .infos .line {
  border: none;
  border-bottom: 1px solid var(--box-up);
  height: 0px;
  width: 70px;
}
.campaign-name p {
  width: 100%;
  text-align: center;
}
.msg-charts {
  display: none;
}
.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tags .tag {
  color: rgba(170, 170, 171, 0.7);
  font-weight: bold;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 5px;
  margin-right: 5px;
}
.tabs {
  margin-bottom: 40px;
}
.tabs ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}
.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

.funnels {
  display: flex;
  align-items: center;
}
.funnels .line {
  border: none;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  height: 0px;
  width: 20px;
}
.funnel {
  border-radius: 10px;
  width: fit-content;
  display: flex;
  background-color: #292e32;
  padding: 10px 20px;
  min-width: 155px;
}
.funnel-value {
  color: #cbcbcb;
  border-bottom: none;
  display: flex;
  align-items: center;
}
.funnel-value p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 800;
}
.funnel-value p.empty {
  color: #898a8d;
  text-align: center;
  font-weight: normal;
}
.funnel-name {
  display: flex;
  color: #cccccc8c;
  border-right: 1px solid #2f3439;
  padding-right: 15px;
  margin-right: 15px;
}
.funnel-name p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}
.message {
  color: rgba(204, 204, 204, 0.55);
  font-size: 12px;
  line-height: 18px;
  background: #282d30;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-style: italic;
}
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.exp-reminder {
  padding: 20px 20px 10px;
  background: #2a3034;
  border-radius: 10px;
  margin-top: 15px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 300px);
}
.success {
  background: #2d3338;
  border-radius: 10px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success i {
  color: #82d997;
  font-size: 30px;
  margin-bottom: 25px;
}

.success p {
  color: rgba(204, 204, 204, 0.75);
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .details-campaign {
    width: 20%;
    min-width: 385px;
  }
  .wrapper {
    display: flex;
  }
  .campaign-infos {
    margin-left: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .details-campaign {
    width: 100%;
  }
  .dashboard-campaign {
    margin-left: 0;
    width: 100%;
  }
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .tabs {
    margin-bottom: 25px;
  }

  .buttons-header :is(.fa-chevron-down, .fa-chevron-up) {
    height: 18px;
  }
  .details-campaign .campaign-name {
    padding: 10px;
  }
  .funnels {
    margin-bottom: 10px;
    flex-direction: column;
    width: 100%;
  }
  .funnels .line {
    height: 0px;
    width: 10px;
    margin: auto;
    transform: rotate(90deg) translateX(-5px);
  }
  .funnels .funnel {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .funnels .funnel .funnel-value {
    width: 100%;
    justify-content: center;
  }
  .funnels .funnel .funnel-name {
    min-width: 80px;
  }
  .funnels .funnel .funnel-value p {
    color: #cccccc;
    font-size: 14px;
  }
  .totalizer {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 13px;
  }
  .totalizer .card {
    padding: 20px;
    align-items: center;
  }
  .totalizer .card .value {
    font-size: 16px;
    line-height: 19px;
  }
  .totalizer .card p:nth-child(2) {
    margin-top: 5px;
    padding-bottom: 5px;
  }
  .campaign-infos .infos {
    flex-direction: column;
    margin: 15px 0;
  }
  .campaign-infos .infos .card {
    padding: 5px 20px;
  }
  .campaign-infos .infos .card:not(:last-child) {
    margin-bottom: 10px;
  }
  .campaign-infos .infos .card p:nth-child(1) {
    min-width: 80px;
  }
  .campaign-infos .infos .card p:nth-child(2) {
    width: 100%;
    justify-content: center;
  }
  .campaign-infos .infos .line {
    height: 0px;
    width: 10px;
    margin: auto;
    transform: rotate(90deg) translateX(-5px);
  }
  .details-campaign .step {
    padding: 15px 0;
  }
  .details-campaign .step p:nth-child(1) {
    margin-bottom: 10px;
  }
}

.textarea-custom {
  position: relative;
}

.textarea-custom .infos {
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  align-items: center;
}

[contenteditable]:focus {
  outline: none;
}

.textarea-custom .infos .sender[contenteditable]:focus {
  border: 1px solid var(--default);
}

.textarea-custom .infos .benefit-code[contenteditable]:focus {
  background: transparent;
  border: 1px solid var(--default);
}

.textarea-custom .infos .url[contenteditable]:focus {
  border: 1px solid var(--default);
}

.textarea-custom .infos :is(.sender, .benefit-code, .url) {
  border: 1px solid transparent;
  border-radius: 10px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 5px 10px;
  margin-right: 10px;
}

.textarea-custom .infos .sender {
  background: #282d30;
  color: #a8b3bf;
}

.textarea-custom .infos .benefit-code {
  background: rgba(97, 97, 255, 0.45);
  color: rgba(255, 255, 255, 0.85);
}

.textarea-custom .infos .url {
  color: #aaaaaa;
}

.textarea-custom .chars-left {
  position: absolute;
  bottom: -30px;
  right: 0;
  color: rgba(255, 241, 173, 0.65);
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}
</style>
