<template>
  <div>Erro de número minimo de usuários</div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
div {
  display: flex;
  justify-content: center;
  font-size: 2.5em;
}
</style>
