<template>
  <div>
    <base-title :title="typeMode()" link="performance" subtitle="Campanhas">
      <base-button
        v-if="isEditing"
        text="Excluir campanha"
        design="two"
        :class="!!isMobile ? 'reverse mb_15 w-100' : 'mr_15'"
        @click="removeCampaign()"
      />
    </base-title>
    <base-box v-if="successCampaign">
      <div class="success-box">
        <div class="icon-base">
          <i class="far fa-check" />
        </div>
        <p v-if="isEditing">Campanha editada com sucesso!</p>
        <p v-else>Campanha criada com sucesso!</p>
      </div>
    </base-box>

    <div v-else class="tabs">
      <div class="tab-container" v-if="!showOverview">
        <tab-options
          :campaign.sync="campaign"
          :activeTab="activeTab"
          :tabStep="tabStep"
          @selectTab="selectTab"
        />
        <tab-content
          :activeTab="activeTab"
          :loading="false"
          :tabStep="tabStep"
          :campaign.sync="campaign"
          @goToStep="goToStep"
          @save="saveCampaign"
        />

        <div class="tab-active" v-if="isMobile">
          <p
            @click="tabStep = 'steps'"
            :class="{ active: tabStep === 'steps' }"
          >
            Passos
          </p>
          <p
            @click="tabStep = 'tab-content'"
            :class="{ active: tabStep === 'tab-content' }"
          >
            Criação
          </p>
        </div>
      </div>
      <div class="tab-container" v-else>
        <overview
          :activeTab="activeTab"
          :key="`overview-${Date.now()}`"
          :tabStep="tabStep"
          :campaign="campaign"
          :buttonText="
            campaign.initial_flag > 1 ? 'Iniciar análise' : 'Salvar Campanha'
          "
          @goToStep="goToStep"
          @save="saveCampaign"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/Button';
import BaseBox from '@/components/Box';
import BaseTitle from '@/components/Title';
import TabOptions from '@/containers/Performance/TabOptions';
import TabContent from '@/containers/Performance/TabContent';
import Overview from '@/containers/Performance/Overview';
import { EnvironmentAPI } from '@/api/Environment';
import Utils from '@/utils';

const components = {
  BaseButton,
  BaseBox,
  BaseTitle,
  TabOptions,
  TabContent,
  Overview,
};

const STEPS = {
  NAME_STEP: 1,
  TRIGGER_STEP: 2,
  FIRING_STEP: 3,
  DELIVERY_STEP: 4,
  SEGMENTATION_STEP: 5,
  ANALYSIS_STEP: 6,
  FINISH_STEP: 7,
};

export default {
  components,
  data() {
    return {
      isEditing: false,
      campaign: {
        type: 'RE',
        configs: {
          climate: null,
          weather: [],
          send_time: {
            time: null,
          },
          interval: null,
          single_conversion: null,
        },
      },
      activeTab: 1,
      tabStep: 'steps',
      successCampaign: false,
      showOverview: false,
    };
  },
  async mounted() {
    this.loadCampaign();
  },
  methods: {
    typeMode() {
      const { id } = this.$route.params;
      switch (id !== undefined) {
        case id === undefined && this.showOverview:
          return this.StringLanguage.createCampaign;
        case id === undefined && !this.showOverview:
          return this.StringLanguage.createOverview;
        case id !== undefined:
          return this.StringLanguage.campaignEdit;
        default:
          this.showOverview = false;
          break;
      }
    },
    async loadCampaign() {
      if (this.$route.params.id) {
        const campaignId = this.$route.params.id;
        const response = await EnvironmentAPI.getCampaignsId(campaignId);
        this.campaign = response.getData([]);
        this.campaign.rules = this.campaign.audience_rules;
        delete this.campaign.audience_rules;
        this.isEditing = true;
      }

      this.$forceUpdate();
    },

    async removeCampaign() {
      const campaignId = this.$route.params.id;
      confirm(
        `Você tem certeza que deseja excluir a campanha? Ao fazer isso todas as configurações serão perdidas`,
        async (res) => {
          if (!res) return;
          await EnvironmentAPI.removeCampaign(campaignId);
          this.$router.push({ name: 'performance' });
        }
      );
    },

    selectTab({ tabIndex }) {
      this.activeTab = tabIndex;

      if (this.isMobile) {
        this.tabStep = 'tab-content';
      }
    },
    nextTab() {
      if (this.activeTab === STEPS.NAME_STEP) {
        this.activeTab = STEPS.TRIGGER_STEP;
      } else if (this.activeTab === STEPS.TRIGGER_STEP) {
        this.activeTab = STEPS.FIRING_STEP;
      } else if (this.activeTab === STEPS.FIRING_STEP) {
        this.activeTab = STEPS.DELIVERY_STEP;
      } else if (this.activeTab === STEPS.DELIVERY_STEP) {
        this.activeTab = STEPS.SEGMENTATION_STEP;
      } else if (this.activeTab === STEPS.SEGMENTATION_STEP) {
        this.activeTab = STEPS.ANALYSIS_STEP;
      } else if (this.activeTab === STEPS.ANALYSIS_STEP) {
        this.activeTab = STEPS.FINISH_STEP;
        this.showOverview = true;
      } else if (this.activeTab === STEPS.FINISH_STEP) {
        this.showOverview = false;
        this.successCampaign = true;

        setTimeout(() => {
          this.changeRoute('performance');
        }, 5000);
      }
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    async saveCampaign(data) {
      const configs = { ...this.campaign.configs, ...data.configs };
      delete this.campaign.configs;
      this.campaign = { ...this.campaign, ...data, ...{ configs } };
      this.nextTab();
    },
    goToStep(tabIndex, data) {
      this.campaign = Object.assign(this.campaign, data);
      this.showOverview = false;
      this.activeTab = tabIndex;
      if (tabIndex === 7) {
        this.nextTab();
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.tab-active {
  display: none;
}

@media screen and (max-width: 991px) {
  .tab-active {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    margin-top: 30px;
    border-top: 1px solid rgba(111, 118, 126, 0.25);
  }
  .tab-active p {
    font-size: 14px;
    line-height: 17px;
    color: rgba(170, 170, 171, 0.85);
    font-weight: 300;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }
  .tab-active p.active {
    font-weight: bold;
    color: var(--default);
    border-color: var(--default);
  }
}

.tabs {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 991px) {
  .tabs {
    padding-bottom: 50px;
  }
}
.tab-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: var(--box);
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
}
@media screen and (max-width: 991px) {
  .tab-container {
    display: block;
    padding: 0;
  }
}
.tab-container .tab-options .tab {
  padding: 20px 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.1s ease;
  min-height: 100px;
}

.tab-container .tab-options .tab:not(.enabled) {
  cursor: not-allowed;
}

.tab-container .tab-options .tab .number {
  background: #46484d;
  border-radius: 50%;
  font-weight: bold;
  color: #aaaaab;
  font-size: 12px;
  margin-right: 17px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
}
@media screen and (max-width: 991px) {
  .tab-container .tab-options .tab {
    min-height: 80px;
    padding: 20px;
    transition: none;
  }
  .tab-container .tab-options .tab .number {
    width: 22px;
    height: 22px;
  }
  .tab-container .tab-options .tab .number.done {
    background: #288c79;
    color: #fff;
  }
}
.tab-container .tab-options .tab .name {
  font-size: 14px;
  line-height: 17px;
  color: #999;
  margin-bottom: 10px;
}
.tab-container .tab-options .tab p:not(.name) {
  color: #aaaaabd9;
  line-height: 15px;
  font-size: 12px;
}
.tab-container .tab-options .tab:not(.active).enabled:hover {
  background: #292e32;
}
.tab-container .tab-content {
  position: relative;
  width: 100%;
  padding: 20px 25px;
  background: var(--box);
  overflow: auto;
  margin-left: 20px;
  border-radius: 10px;
  border: 1px solid #2e3438;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 991px) {
  .tab-container .tab:after {
    content: '\f054';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    right: 25px;
    font-size: 12px;
    color: #8c8c8c;
  }
  .tab-container .tab-content {
    width: 100%;
    margin: 0;
    position: relative;
    padding: 20px 20px;
    border: none;
  }
}
@media screen and (min-width: 992px) {
  .tab-container .tab.active {
    background: var(--background-secondary);
  }
  .tab-container .tab-options .tab:not(:last-child) {
    margin-bottom: 15px;
  }
  .tab-container .tab-options .tab.active .number {
    background: var(--default);
    color: #fff;
  }
  .tab-container .tab-options .tab.active .name {
    color: var(--default);
    font-weight: bold;
  }
  .tab-container .tab-options .tab.active p:not(.name) {
    font-weight: bold;
  }
  .header-mobile {
    display: none;
  }
  .coupons {
    max-height: 600px;
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
  }
}
@media screen and (max-width: 991px) {
  .tab-container .tab-options .tab .name {
    color: var(--label-secondary);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  .tab-container .tab-options .tab p:not(.name) {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #aaaaabf2;
  }
  .tab-container .tab-options:before {
    left: 30px;
  }
  .tab-container .tab-options.step-1:before {
    background: linear-gradient(to bottom, #288c79 0%, #aaaaab33 0%);
  }
  .tab-container .tab-options.step-2:before {
    background: linear-gradient(to bottom, #288c79 15%, #aaaaab33 15%);
  }
  .tab-container .tab-options.step-3:before {
    background: linear-gradient(to bottom, #288c79 15%, #aaaaab33 35%);
  }
  .tab-container .tab-options.step-4:before {
    background: linear-gradient(to bottom, #288c79 35%, #aaaaab33 60%);
  }
  .tab-container .tab-options.step-5:before {
    background: linear-gradient(to bottom, #288c79 60%, #aaaaab33 70%);
  }
  .tab-container .tab-options.step-6:before {
    background: linear-gradient(to bottom, #288c79 80%, #aaaaab33 90%);
  }
  .tab-container .tab-options.step-7:before {
    background: linear-gradient(to bottom, #288c79 90%, #aaaaab33 100%);
  }
}
.title {
  color: #aaaaab;
  line-height: 19px;
  font-size: 16px;
  font-weight: bold;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ffffff17;
  padding-bottom: 25px;
  position: relative;
}
.help {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  color: var(--label-secondary);
  cursor: pointer;
}
.help:hover {
  border-color: var(--label-secondary);
  color: #e6e6e6;
}
.help i {
  font-size: 15px;
  margin-right: 10px;
}
.help p {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
}
.collapse .option {
  background: var(--box-up);
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.collapse .option:hover {
  background: #2e3438bd;
}
.collapse .option .chevron {
  position: absolute;
  right: 10px;
  color: var(--icon-inactive);
}
.collapse .option .chevron i {
  transition: all 0.2s;
  font-size: 11px;
}
.collapse .option .chevron.active i {
  transform: rotate(180deg);
}
.collapse .option i {
  font-size: 15px;
  margin-right: 10px;
  color: var(--icon-inactive);
}
.collapse .option p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
}
.collapse .content {
  padding: 10px 0 5px 0;
}
.cost {
  background: var(--box);
  border: 1px solid #ffcc9914;
  border-radius: 12px;
  color: #d1802e;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
}
.has.cost {
  background: #ffcc9914;
  border: 1px solid transparent;
}
.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.tags .tag {
  color: var(--label-secondary);
  font-weight: bold;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  border: 1px solid #ffffff17;
  margin-bottom: 5px;
  margin-right: 5px;
}
.coupons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.textarea-msg {
  position: relative;
}

.textarea .characters {
  position: absolute;
}

@media screen and (max-width: 991px) {
  .coupons {
    grid-template-columns: repeat(1, 1fr);
  }
  .tabs-steps {
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #2d2f32;
    position: fixed;
    bottom: 60px;
    padding: 5px;
    left: 0;
    right: 0;
  }
  .header {
    display: none;
  }
  .header-mobile {
    color: var(--default);
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 15px;
    padding-top: 3px;
    margin-bottom: 20px;
    font-weight: bold;
    border-bottom: 1px solid #ffffff17;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  .tags .tag {
    color: #aaaaabf2;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #505359;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.characters {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 0 20px;
}

.characters:before {
  position: absolute;
  left: 1px;
  top: -7.5px;
  content: '';
  background: #515159;
  width: calc(100% - 2px);
  height: 1px;
}

.characters .circle {
  position: relative;
  width: 30px;
  height: 30px;
}

.characters .circle svg {
  position: relative;
  width: 40px;
  height: 40px;
  transform: rotate(270deg);
}

.characters .circle svg circle {
  width: 30px;
  height: 30px;
  fill: none;
  stroke-width: 2;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 94;
  stroke-dashoffset: 94;
}
.characters .circle svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #515159;
}
.characters .circle svg circle:nth-child(2) {
  stroke: #ff3382;
}
.characters .circle .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #aaaaab;
  font-weight: bold;
  transform: translate(5px, 5px);
}
.success-box {
  padding: 25px 20px 20px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box);
}
.success-box .icon-base {
  background-color: #4c4cff;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-box i {
  color: #fff;
  font-size: 17px;
}
.success-box p {
  color: #6161ff;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-top: 25px;
}
.custom-period {
  background: var(--box-up);
  padding: 15px 15px 5px;
  margin-top: 20px;
}
.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.address-box {
  display: flex;
  align-items: stretch;
  margin: 10px 0;
}
.address-box .address {
  background: var(--box-up);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #aaaaaaed;
}
.address-box .address p {
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 300;
}
.address-box .address i {
  font-size: 15px;
  color: var(--icon-inactive);
}
.shot-method-selected,
.shot-method-selected + span {
  color: var(--label-secondary);
  font-size: 12px;
  line-height: 15px;
}

.week {
  display: flex;
}

.day {
  font-size: 1.2em;
  width: 29px;
  height: 29px;
  color: var(--label-primary);
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.day.selected {
  color: white;
  border-color: var(--default);
  background: var(--default);
}
</style>
