<template>
  <div>
    <base-title title="Públicos">
      <base-button
        v-if="!isMobile"
        :class="!!isMobile ? 'reverse w-100 mb_15' : ''"
        @click="changeRoute('criar-publico')"
      >
        Criar público
      </base-button>
      <base-button
        v-if="isMobile"
        design="two"
        :class="isMobile ? 'reverse w-100' : ''"
        @click="changeRoute('criar-publico')"
      >
        <p v-if="!isMobile">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </p>
        <p v-else>Criar público</p>
      </base-button>
    </base-title>

    <tab-header
      :selectedTab="selectedTab"
      @onTabChange="(tabIndex) => (selectedTab = tabIndex)"
    />

    <div v-if="selectedTab === TABS.CUSTOM">
      <div
        v-if="pagination.next == -1 && audiences.length == 0"
        class="empty-wrapper-container"
      >
        <base-empty
          title="Você ainda não tem públicos cadastrados."
          message="Todas os públicos irão aparecer aqui."
        >
          <template slot="image">
            <i class="far fa-users" />
          </template>
        </base-empty>
      </div>
      <div v-else>
        <customs-tab
          :audiences="audiences"
          @onEdit="(audienceId) => edit(audienceId)"
          @onExport="(audienceId) => openDrawer(audienceId)"
        />
      </div>
      <infinite-loading v-if="pagination.next > -1" @infinite="loadAudiences">
        <span slot="no-more"></span>
        <span slot="spinner">
          <base-loading />
        </span>
      </infinite-loading>
    </div>

    <div v-if="selectedTab === TABS.SUGGESTED">
      <div class="empty-wrapper-container">
        <base-empty
          title="Você não possui públicos sugeridos."
          message="Sugestões e oportunidades baseadas em histórico de predição."
        >
          <template slot="image">
            <i class="far fa-users" />
          </template>
        </base-empty>
      </div>
    </div>

    <base-drawer
      title="Exportar"
      :open="exportDrawer.open"
      :close="exportDrawer.close"
      @onClose="closeDrawer()"
    >
      <base-card>
        <base-label text="Qual formato gostaria de exportar?" />
        <base-radio
          family="format"
          option="json"
          v-model="exportDrawer.exportType"
        >
          <p class="radio-label">JSON</p>
        </base-radio>
        <base-radio
          family="format"
          option="csv"
          class="mt_15"
          v-model="exportDrawer.exportType"
        >
          <p class="radio-label">CSV</p>
        </base-radio>
        <base-radio
          family="format"
          option="xml"
          class="mt_15"
          v-model="exportDrawer.exportType"
        >
          <p class="radio-label">XML</p>
        </base-radio>
      </base-card>
      <template slot="footer">
        <base-button
          class="w-100"
          :disabled="true"
          v-if="exportDrawer.exportLoading"
        >
          Exportandos dados...
        </base-button>
        <base-button
          class="w-100"
          @click="exportAudience()"
          :disabled="!exportDrawer.exportType"
          v-else
        >
          Realizar download
        </base-button>
      </template>
    </base-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseBox from '@/components/Box';
import BaseMessage from '@/components/Message';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseEmpty from '@/components/Empty';
import BaseLabel from '@/components/Label';
import BaseLoading from '@/components/Loading';
import BaseRadio from '@/components/Radio';
import BaseBadge from '@/components/Badge';
import { AudienceAPI } from '@/api/AudienceAPI';
import TabHeader from './components/TabHeader';
import CustomsTab from './components/CustomsTab';

const components = {
  BaseButton,
  BaseTitle,
  BaseDrawer,
  BaseCard,
  BaseEmpty,
  BaseLabel,
  BaseLoading,
  BaseBox,
  BaseMessage,
  BaseRadio,
  BaseBadge,
  InfiniteLoading,
  TabHeader,
  CustomsTab,
};

const TABS = { CUSTOM: 0, SUGGESTED: 1 };

export default {
  components,
  data() {
    return {
      TABS,
      selectedTab: TABS.CUSTOM,
      audiences: [],
      pagination: {
        next: 0,
        limit: 18,
      },
      exportDrawer: {
        exportType: 'json',
        audienceId: null,
        exportLoading: false,
        open: false,
        close: false,
      },
    };
  },
  methods: {
    async loadAudiences($state) {
      const { pagination } = this;

      if (pagination.next === -1) {
        return;
      }

      const audiencesResponse = await AudienceAPI.getAudiences(
        pagination.next,
        pagination.limit
      );
      const audiences = audiencesResponse.getData({ items: [] });
      this.audiences.push(...audiences.items);

      this.pagination.next = audiences.next.page || -1;

      if (this.pagination.next != -1) {
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    async edit(audienceId) {
      const audienceResponse = await AudienceAPI.getAudienceById(audienceId);
      if (audienceResponse.getErrors([]).length > 0) {
        return alert(audienceResponse.getErrors([])[0], 'Ok, entendi.');
      }

      const audience = audienceResponse.getData({ campaigns: [] });
      if (audience.campaigns.length > 0) {
        alert(
          'Não é possível editar este público pois ele está vinculado à alguma campanha ativa.',
          'Ok, entendi.'
        );
        return;
      }

      return this.changeRoute('editar-publico', { id: audienceId });
    },
    openDrawer(audienceId) {
      this.exportDrawer.open = !this.exportDrawer.open;
      this.exportDrawer.close = false;
      this.exportDrawer.audienceId = audienceId;
    },
    closeDrawer() {
      this.exportDrawer.open = false;
      this.exportDrawer.close = !this.exportDrawer.close;
      this.exportDrawer.audienceId = null;
    },
    async exportAudience() {
      try {
        const { exportType, audienceId } = this.exportDrawer;
        this.exportDrawer.exportLoading = true;

        const urlToDownloadResponse = await AudienceAPI.exportAudience(
          audienceId,
          exportType
        );

        if (urlToDownloadResponse.getErrors([]).length > 0) {
          alert(urlToDownloadResponse.getErrors([])[0], 'Ok, entendi.');
          return;
        }

        const urlToDownload = urlToDownloadResponse.getData({ url: '' });
        const a = document.createElement('a');
        a.href = urlToDownload.url;
        a.download = true;
        a.click();
      } catch (err) {}
      this.exportDrawer.exportLoading = false;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.tabs {
  margin-bottom: 40px;
}

.tabs ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}

.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}

.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

.publics {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: start;
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .publics {
    grid-template-columns: repeat(1, 1fr);
  }
}

.empty-wrapper-container {
  position: relative;
  min-height: 50vh;
}
</style>
