<template>
  <div>
    <div v-if="finishedMount">
      <base-title
        title="Campanhas pausadas"
        subtitle="Campanhas"
        link="performance"
      >
        <base-input
          search
          :records="3"
          placeholder="Buscar por..."
          class="mb_0 pt_0"
          design="search"
          v-if="!isMobile"
        />

        <base-button
          @click="drawer.filter.open = !drawer.filter.open"
          class="m_auto filter"
          :class="isMobile ? 'reverse w-100' : 'ml_15'"
          design="two"
        >
          <p class="filter" v-if="!isMobile">
            <i class="far fa-sliders-h"></i>
          </p>
          <p class="filter" v-else>Filtrar período</p>
        </base-button>
      </base-title>

      <div v-if="activeCampaigns || completedCampaigns">
        <div v-if="loading && activeCampaigns.length > 0" class="loading">
          <base-loading />
        </div>

        <div class="campaigns">
          <campaign-box
            v-for="(campaign, index) in fakeCampaigns"
            :key="index"
            :campaign="campaign"
          />
        </div>
      </div>

      <base-drawer
        title="Filtrar por"
        :open="drawer.filter.open"
        :close="drawer.filter.close"
      >
        <base-card>
          <base-label text="Status de campanha" class="mb_20" />
          <base-checkbox
            @click="formatterQuery('status', 'DRAFT')"
            class="mb_10"
            >Rascunho</base-checkbox
          >
          <base-checkbox
            @click="formatterQuery('status', 'ACTIVE')"
            class="mb_10"
            >Ativa</base-checkbox
          >
          <base-checkbox
            @click="formatterQuery('status', 'SCHEDULED')"
            class="mb_10"
            >Agendada</base-checkbox
          >
          <base-checkbox
            @click="formatterQuery('status', 'FINISHED')"
            class="mb_10"
            >Finalizada</base-checkbox
          >
        </base-card>

        <template slot="footer">
          <base-button @click="handleFilter" class="w-100 big"
            >Salvar</base-button
          >
        </template>
      </base-drawer>

      <base-drawer
        title="Opções"
        :open="drawer.options.open"
        :close="drawer.options.close"
      >
        <base-card>
          <div class="grid-options">
            <div class="option" @click="changeRoute('campanhas-arquivadas')">
              <i class="fal fa-pause" />
              <p>Ver campanhas arquivadas</p>
            </div>
          </div>
        </base-card>
      </base-drawer>

      <infinite-loading
        v-if="activeCampaigns.length > 0"
        :identifier="1"
        @infinite="infinityCampaign"
      >
        <span slot="spinner">
          <base-loading />
        </span>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseCheckbox from '@/components/Checkbox';
import PerformanceApi from '@/api/modules/performance';
import ModulesApi from '@/api/modules/modules';
import BaseLoading from '@/components/Loading';
import Utils from '@/utils';

import CampaignBox from '@/containers/Modules/Performance/CampaignBox';

import customStorage from '@/plugins/customStorage';

export default {
  data() {
    return {
      finishedMount: true,
      activeCampaigns: [],
      filteredCampaigns: [],
      contentLoading: true,
      loading: true,
      search: '',
      queryType: [],
      queryStatus: [],
      offset: 1,
      selectedTab: 0,
      isTyping: false,
      count: 0,
      drawer: {
        filter: {
          open: false,
          close: false,
        },
        options: {
          open: false,
          close: false,
        },
      },
      query: [],
      type: [],
      completedCampaigns: [],
      fakeCampaigns: [
        {
          name: 'Campanha F',
          averageReach: null,
          initialReach: 0,
          creatives: 31,
          status: 'ARCHIVED',
          sends: 1273,
          clicks: 1273,
          conversions: 1273,
          learning: false,
        },
      ],
    };
  },
  components: {
    BaseButton,
    BaseTitle,
    BaseLabel,
    BaseInput,
    BaseDrawer,
    BaseCard,
    BaseCheckbox,
    InfiniteLoading,
    BaseLoading,
    CampaignBox,
  },
  async created() {
    const campaigns = await this.getCampaigns({
      status: this.getStatusBySelectedTab(),
    });

    this.offset++;
    this.activeCampaigns = campaigns;
    this.loading = false;
  },
  methods: {
    openDrawerOptions() {
      this.drawer.options.open = !this.drawer.options.open;
    },
    nullishCoalescing(startPoint, jsonPath, defaultValue = null) {
      const props = jsonPath.split('.');
      let mainProp = startPoint;
      for (const prop of props) {
        mainProp = mainProp[prop];
        if (!mainProp) {
          return defaultValue;
        }
      }
      return mainProp;
    },
    async formatterQuery(type, data) {
      if (type === 'type' && !this.queryType.includes(data)) {
        this.queryType.push(data);
      } else if (this.queryType.includes(data)) {
        this.queryType.splice(this.queryType.indexOf(data), 1);
      }

      if (type === 'status' && !this.queryStatus.includes(data)) {
        this.queryStatus.push(data);
      } else if (this.queryStatus.includes(data)) {
        this.queryStatus.splice(this.queryStatus.indexOf(data), 1);
      }
    },
    async updateStatus(campaign) {
      if (!campaign.action) {
        return;
      }

      const newStatus =
        ['ARC', 'ARD'].indexOf(campaign.action) > -1 ? 'ACTIVE' : 'SCHEDULED';
      await PerformanceApi.updateCampaign({ status: newStatus }, campaign.id);
      campaign.status = newStatus;
    },
    async getCampaigns({ status: stausItem, type }) {
      const response = await PerformanceApi.getCampaigns(
        {
          status: stausItem,
          type,
          search: this.search || null,
        },
        this.offset
      );

      const campaignsItems = response.getData([]);
      this.count = campaignsItems.campaign.count;
      const campaigns = this.nullishCoalescing(
        campaignsItems,
        'campaign.rows',
        []
      ).map((item) => {
        const lastItem = item.reports[item.reports.length - 1];
        let conversionsCount = 0;
        let clicksCount = 0;
        let sendsCount = 0;
        let averageReachPreview = 0;
        let initialReachPreview = 0;
        let ordersTotal = 0;

        item.reports.map((a) => {
          conversionsCount += a.conversions;
          clicksCount += a.clicks;
          sendsCount += a.sends;
          ordersTotal += a.orders_total;
        });

        if (lastItem) {
          averageReachPreview = lastItem.averageReach_preview;
          initialReachPreview = lastItem.averageReach_preview;
        }

        const { id, name, configs, status } = item;
        return {
          id,
          name,
          averageReachPreview,
          initialReachPreview,
          sends: sendsCount,
          clicks: clicksCount,
          ordersTotal,
          conversions: conversionsCount,
          action: configs ? configs.type : null,
          status,
          segmentations: this.nullishCoalescing(item, `segmentation_rules`, []),
          message: this.nullishCoalescing(
            item,
            'configs.content.sms.message.text'
          ),
        };
      });

      return campaigns;
    },

    async infinityCampaign($state) {
      const statusQuery = Object.keys(this.queryStatus).map(
        (key) => this.queryStatus[key] && key
      );
      const typeQeury = Object.keys(this.queryType).map(
        (key) => this.queryType[key] && key
      );
      const isQuery = statusQuery.length > 0 || typeQeury.length > 0;
      let campaign = null;

      if (isQuery) {
        campaign = await this.getCampaigns({
          status: Object.keys(this.queryStatus).map(
            (key) => this.queryStatus[key] && key
          ),
          type: Object.keys(this.queryType).map(
            (key) => this.queryType[key] && key
          ),
        });
      } else {
        campaign = await this.getCampaigns({
          status: this.getStatusBySelectedTab(),
        });
      }

      if (campaign.length > 0) {
        if (this.getStatusBySelectedTab() === 'ACTIVE' && !isQuery) {
          this.activeCampaigns.push(...campaign);
          this.offset++;
        } else if (this.getStatusBySelectedTab() === 'FINISHED' && !isQuery) {
          this.completedCampaigns.push(...campaign);
          this.offset++;
        } else {
          this.filteredCampaigns.push(...campaign);
          this.offset++;
        }

        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async handleSearch() {
      if (this.getStatusBySelectedTab() === 'ACTIVE') {
        this.activeCampaigns = await this.getCampaigns({
          search: this.search,
          status: this.getStatusBySelectedTab(),
        });
        this.offset++;
      } else {
        this.completedCampaigns = await this.getCampaigns({
          search: this.search,
          status: this.getStatusBySelectedTab(),
        });
      }
      // eslint-disable-next-line prettier/prettier
      this.loading = false;
    },
    async handleFilter() {
      this.drawer.filter.close = false;
      this.loading = true;
      this.offset = 1;

      this.filtedCampaigns = await this.getCapaigns({
        status: this.queryStatus,
        type: this.queryType,
      });
      this.offset++;
      this.loading = false;
      this.drawer.filter.close = true;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    getStatusBySelectedTab() {
      return this.selectedTab === 0 ? 'ACTIVE' : 'FINISHED';
    },
  },
  watch: {
    search: Utils.debounce(function () {
      this.isTyping = false;
    }, 1000),
    async isTyping(value) {
      this.loading = true;
      if (!value) {
        this.offset = 1;
        await this.handleSearch();
      }
    },
    async selectedTab(value) {
      this.offset = 1;
      this.loading = true;
      this.completedCampaigns = await this.getCampaigns({
        status: this.getStatusBySelectedTab(),
      });
      this.loading = false;
      this.offset++;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.create-campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.create-campaign i {
  color: #8c8c8c99;
  font-size: 70px;
  margin-bottom: 25px;
}
.create-campaign h1 {
  color: var(--label-secondary);
  font-size: 20px;
  line-height: 24px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 25px;
}
.create-campaign p {
  color: #aaaaabbf;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 35px;
}
.btn-create {
  width: 185px;
}
.balance {
  color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
}

.balance .value {
  border: 1px solid #0dc59d73;
  background: #0dc59d14;
  border-radius: 5px;
  padding: 12px 35px;
  line-height: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #0dc59d;
  font-family: 'Lato';
}

.balance .value span {
  font-weight: 900;
  color: #0ed9ad;
}

@media screen and (max-width: 991px) {
  .create-campaign i {
    color: #8c8c8c99;
    font-size: 55px;
    margin-bottom: 20px;
  }
  .create-campaign h1 {
    margin-bottom: 15px;
  }
  .create-campaign p {
    line-height: 20px;
    margin-bottom: 25px;
    text-align: center;
  }
  .balance .value {
    padding: 10px;
  }
}

.tabs ul li {
  display: inline;
  text-align: center;
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 6px;
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  color: #999999ad;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

.campaigns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0 25px;
}

.occurrences {
  background: var(--box-up);
  padding: 4px 12px;
  margin-left: 10px;
  border-radius: 5px;
  color: #aaaaaae6;
  font-size: 14px;
  line-height: 17px;
  position: relative;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .campaigns {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
